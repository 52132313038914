import React from "react";
import { useContext } from "react";
import { Context } from "./contexts/Context";

export default function Profile() {
  const { t } = useContext(Context);

  return (
    <div className="profile">
      <h1 className="profile-title">{t("profileTitle")}</h1>

      <div className="profile-content">
        <div className="component-container">
          <div className="basic-info">
            <h4 className="info-title">{t("profileHeader")}</h4>
            <div className="pro-column">
              <div className="basic-key">
                <ul>
                  <li className="flex flex-row"> 
                    <h5 id="info-keys"> {t("profileBirthDate")}</h5>
                     <span id="info-value"> {t("profileBirthValue")}</span>
                  </li>
                  <li className="flex flex-row">
                    <h5 id="info-keys">{t("profileCity")}</h5> 
                    <span id="info-value">{t("profileCityValue")}</span>
                  </li>
                  <li className="flex flex-row">
                    <h5 id="info-keys">{t("profileEducation")}</h5>
                    <span id="info-value">{t("profileEducationValue")}</span>
                  </li>
                  <li className="flex flex-row">
                    <h5 id="info-keys">{t("profileRole")}</h5>
                    <span id="info-value">{t("profileRoleValue")}</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="component-container">
          <div className="profile-image">
            <img className="text-center" src="ayse.png" width={300} height={300} />
          </div>
        </div>

        <div className="component-container">
          <div className="about-box">
            <h4 className="info-title">{t("aboutMe")}</h4>

            <p className="about-me pb-5">{t("aboutMeParagraph1")}</p>
            <p className="about-me">{t("aboutMeParagraph2")}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
