import React from "react";
import { useContext } from "react";
import { Context } from "./contexts/Context";

export default function Footer() {
  const { t, i18n, handleLanguageChange } = useContext(Context);
  const currentLanguage = i18n.language;
  const cvLink = currentLanguage === "tr" ? "/AYŞE NUR ŞAHİN ÖZGEÇMİŞ.pdf" : "/AYSE NUR SAHİN CURRICULUM VITAE.pdf";

  return (
    <div className="footer_Container">
      <div className="footer">
        <div className="footerText">
          <h2 className="lets">{t("lets1")}</h2>
          <h3 className="lets-text">{t("lets2")}</h3>

          <div className="footerLinks">
            <a
              href="mailto:aysesah.95@gmail.com"
              className="mail"
              onClick={() => {
                alert(t("mailAlert"));
              }}
            >
              aysesah.95@gmail.com
            </a>

            <div className="links-footer">
              <a
                href={cvLink}
                className="cvLink"
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  alert(t("cvDownloadAlert"));
                }}
              >
                {t("cv")}
              </a>
              <a
                href="https://github.com/ayseesahin"
                className="githubFooter"
                target="_blank"
                onClick={() => {
                  alert(t("githubAlert"));
                }}
              >
                Github
              </a>
              <a
                href="https://www.linkedin.com/in/ayşe-nur-şahin-758b66b3/"
                className="linkedinFooter"
                target="_blank"
                onClick={() => {
                  alert(t("linkedinAlert"));
                }}
              >
                Linkedin
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
