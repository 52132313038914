import { useState, useEffect } from "react";
import i18n from "../../i18n";
import { useContext } from "react";
import { Context } from "./contexts/Context"

export default function ModeSwitch() {
 
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("darkMode") === "true"
  );
  const { t, i18n, handleLanguageChange } = useContext(Context);
 
  
  useEffect(() => {
    const body = document.body;
    const footer = document.querySelector(".footer_Container");
    const text = document.querySelector(".lineAndName");
    const textheading = document.querySelector(".heading");
    const introText = document.querySelector(".intro");
    const personalText = document.querySelector(".links-footer p");
    const skillheadingText = document.querySelector(".heading-list");
    const mailText = document.querySelector(".mail");
    const logoBg = document.querySelector(".logo");
    const modeText = document.querySelector(".modeText");
  
    if (body) {
      body.classList.toggle("dark-mode", darkMode);
    }
    if (footer) {
      footer.classList.toggle("dark-mode", darkMode);
    }
    if (text) {
      text.classList.toggle("dark-mode", darkMode);
    }
    if (textheading) {
      textheading.classList.toggle("dark-mode", darkMode);
    }
    if (introText) {
      introText.classList.toggle("dark-mode", darkMode);
    }
    if (personalText) {
      personalText.classList.toggle("dark-mode", darkMode);
    }
    if (skillheadingText) {
      skillheadingText.classList.toggle("dark-mode", darkMode);
    }
    if (mailText) {
      mailText.classList.toggle("dark-mode", darkMode);
    }
    if (logoBg) {
      logoBg.classList.toggle("dark-mode", darkMode);
    }
    if (modeText) {
      modeText.classList.toggle("dark-mode", darkMode);
    }
  
    localStorage.setItem("darkMode", darkMode);
  }, [darkMode]);
  

  const handleToggle = () => {
    setDarkMode(!darkMode);
  };

  return (
    <div className={`App ${darkMode ? "dark-mode" : ""}`}>
      <div className="modeSwitch">
        <div className="mode">
          <label className="toggle-switch">
            <input
              type="checkbox"
              id="toggle-btn"
              className="toggle-switch-checkbox"
              onChange={handleToggle}
              checked={darkMode}
            />
            <span className="slider round"></span>
          </label>
          <div className="modeText cursor-default" onClick={handleToggle}>
            {darkMode ? "LIGHT MODE" : "DARK MODE"}
          </div>
          <div className="modeText">|</div>
          <div className="modeText">
            {i18n.language === "en" ? (
              <span id="lang" onClick={() => handleLanguageChange("tr")}>
                TÜRKÇE
              </span>
            ) : (
              <span id="lang" onClick={() => handleLanguageChange("en")}>
                ENGLISH
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
