import "./App.css";
import Header from "./components/component/header";
import Hero from "./components/component/hero";
import ModeSwitch from "./components/component/modeSwitch";
import SkillsSlider from "./components/skillsSlider";
import Profile from "./components/component/profile";

import ProjectsSlider from "./components/projectsSlider/projectSlider";
import Footer from "./components/component/footer";
import { useTranslation } from "react-i18next";
import { Context } from "./components/component/contexts/Context";
import { useEffect, useState } from "react";

function App() {
  const handleLanguageChange = async (lang) => {
    await i18n.changeLanguage(lang);
    console.log(i18n);
  };
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Context.Provider
      value={{
        handleLanguageChange,
        t,
        i18n,
      }}
    >
      <div className="App">
        {loading ? (
            <div className="loadingScreen">
              <img src="mylogo.png" alt="Loading..." />
            </div>
        ) : (
          <>
            <ModeSwitch />
            <Header />
            <Hero />
            <div id="skillsScroll"></div>
            <SkillsSlider />
            <Profile />
            <div id="projectsScroll"></div>
            <ProjectsSlider />
            <div id="contactScroll"></div>
            <Footer />
          </>
        )}
      </div>
    </Context.Provider>
  );
}

export default App;
