import React, { useContext } from "react";
import Slider from "react-slick";
import { Context } from "../component/contexts/Context";
import projectData from "./projectData.js";
import "./slickProject.css";
import "./slick-themeProject.css";

export default function ProjectsSlider() {
  const { t, i18n, handleLanguageChange } = useContext(Context);
  const { data, dataTr } = projectData;
  const projects = i18n.language === "en" ? data : dataTr;

  const settings = {
    dots: false,
    arrows: true,
    infinite: true,
    vertical: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  function SamplePrevArrow(props) {
    const { className, onClick } = props;
    return <div className={className} onClick={onClick} />;
  }

  return (
    <div className="projectsSlider">
      <div className="projectsContainer">
        <div className="projectCol">
          <h1 className="project-heading">{t("projectsHeading")}</h1>
          <div className="project">
            <Slider
              {...settings}
              nextArrow={<SampleNextArrow />}
              prevArrow={<SamplePrevArrow />}
            >
              {projects.map((project) => (
                <div className="card">
                  <div className="image">
                    <img
                      src={project.projectImg}
                      alt="projects"
                      className="projectsPhotos"
                    />
                  </div>
                  <div className="project-text">
                    <h3 className="content-heading">{project.projectName}</h3>
                    <p className="description-project">
                      {project.projectDescription}
                    </p>
                    <div className="programs">
                      {project.projectProgrammes.map((program) => (
                        <p key={program}>{program}</p>
                      ))}
                    </div>
                    <div className="links">
                      <a
                        href={project.gitHubLink}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => alert(t("githubLinks"))}
                      >
                        GitHub
                      </a>
                      <a
                        href={project.viewSiteLink}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => alert(t("viewSiteLink"))}
                      >
                        {t("viewSite")}
                      </a>
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
    </div>
  );
}
