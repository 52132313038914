import React from "react";
import { Link } from "react-scroll";
import { useContext } from "react";
import { Context } from "./contexts/Context";

export default function Header() {
  const { t, i18n, handleLanguageChange } = useContext(Context);

  return (
    <div className="HContainer">
      <div className="headerContainer">
        <h1 className="logo">A</h1>
        <div className="nav">
          <Link to="skillsScroll" smooth={true} duration={700}>
            <h5>{t("skillsScroll")}</h5>
          </Link>
          <Link>
            <Link to="projectsScroll" smooth={true} duration={700}>
              <h5>{t("projectsScroll")}</h5>
            </Link>
          </Link>
          <Link to="contactScroll" smooth={true} duration={1000}>
            <h4 className="bgColor">{t("contactScroll")}</h4>
          </Link>
        </div>
      </div>
    </div>
  );
}
