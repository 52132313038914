import React from "react";
import { Link } from "react-scroll";
import { useContext } from "react";
import { Context } from "./contexts/Context";


export default function Hero() {
  const { t, i18n, handleLanguageChange } = useContext(Context);
  
  return (
    <div className="heroContainer">
      <div className="hero">
        <div className="hero-left">
          <div className="name">
            <div className="lineAndName">
              <h5 className="line">___________</h5>
              <h5>Ayşe Nur Şahin</h5>
            </div>
          </div>
          
            <h1 className="heading">
              {t("heroHeading1")} <br />
              {t("heroHeading2")}
            </h1>
         
         
            <p className="intro">
            {t("intro")}
            </p>
         
          <div className="buttons">
            <div className="hire-button">
              <Link to="contactScroll" smooth={true} duration={1500}>
                
                  <p className="whiteText">{t("whiteText")}</p>
               
              </Link>
            </div>
            <div className="github-button">
              <img src="GitHub.png" alt="github" />
              
              <a
                href="https://github.com/ayseesahin"
                target="_blank"
                onClick={() => handleLanguageChange("en")
                    ? alert("You are being redirected to the GitHub page!")
                    : alert("Github sayfasına yönlendiriliyorsunuz!")
                }
              >
                Github
              </a>
            </div>
            <div className="linkedin-button">
              <img src="Linkedin.png" alt="linkedin" />
              <a
                href="https://www.linkedin.com/in/ayşe-nur-şahin-758b66b3/"
                target="_blank"
                onClick={() => handleLanguageChange("en")
                    ? alert("You are being redirected to LinkedIn page!")
                    : alert("LinkedIn sayfasına yönlendiriliyorsunuz!")
                }
              >
                Linkedin
              </a>
            </div>
          </div>
        </div>
        <img className="hero-image" src="profil.png" alt="profile" />
      </div>
    </div>
  );
}
