import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  en: {
    translation: {
      heroHeading1: "Creative thinker",
      heroHeading2: "Minimalism lover",
      intro: `Hello, I'm Ayşe. I am a Software Developer. If you're looking for a developer who can create robust and scalable frontend and backend solutions with excellent user experiences, please get in touch with me.`,
      whiteText: "Contact",

      skillsScroll: "Skills",
      projectsScroll: "Projects",
      contactScroll: "Contact",

      profileTitle: "Profile",
      profileHeader: "Basic Information",
      profileBirthDate: "Birth Date",
      profileCity: "City",
      profileEducation: "Education",

      profileRole: "Preferred Role",

      profileBirthValue: "01.01.1996",
      profileCityValue: "İstanbul",
      profileEducationValue:
        "Halic University, Physiotherapy and Rehabilitation, 2019",
      profileRoleValue: "Software Developer",

      aboutMe: "About me",
      aboutMeParagraph1: `I am a Full Stack Developer trying to improve himself. I love
      working as part of a team, taking advantage of others'
      perspectives, collaborating to achieve common goals, and working
      towards making the Web accessible to everyone.`,
      aboutMeParagraph2: `I am open to learning and I believe that anyone can
      be successful in any field they want.`,

      projectsHeading: "Projects",
      githubLinks: "You are being redirected to the GitHub page!",
      viewSiteLink: "You are being redirected to the site!",
      viewSite: "View Site",

      lets1: "Send me a message!",
      lets2: "Got a question or proposal, or just want to say hello? Constact me.",
      cv: "Resume",
      cvDownloadAlert: "You are redirecting to the resume!",
      mailAlert: "Do you want to send mail?",
      githubAlert: "You are being redirected to the GitHub page!",
      linkedinAlert: "You are being redirected to LinkedIn page!",

      skill: "Skills",
    },
  },
  tr: {
    translation: {
      heroHeading1: "Yaratıcı Düşünür",
      heroHeading2: "Minimalist Sever",
      intro: `Merhaba, ben Ayşe. Bir Yazılım Geliştiriciyim. Sağlam, ölçeklenebilir frontend ve backend ürünleri oluşturacak, harika kullanıcı deneyimleri sunacak bir geliştirici arıyorsanız benimle iletişime geçin. `,
      whiteText: "İletişim",

      skillsScroll: "Yetenekler",
      projectsScroll: "Projeler",
      contactScroll: "İletişim",

      profileTitle: "Profil",
      profileHeader: "Kişisel Bilgiler",
      profileBirthDate: "Doğum Tarihi",
      profileCity: "İkamet Şehri",
      profileEducation: "Eğitim Durumu",

      profileRole: "Tercih Ettiği Rol",

      profileBirthValue: "01.01.1996",
      profileCityValue: "İstanbul",
      profileEducationValue:
        "Haliç Üniversitesi, Fizyoterapi ve Rehabilitasyon, 2019",
      profileRoleValue: "Yazılım Geliştirici",

      aboutMe: "Hakkımda",
      aboutMeParagraph1: `Kendini geliştirmeye çalışan bir Full Stack Developer'ım. Bir
      ekibin parçası olarak çalışmak, başkalarının perspektiflerinden
      faydalanmak, ortak hedeflere ulaşmak için işbirliği yapmak ve
      Web'in herkes için erişilebilir olmasını sağlamaya yönelik
      çalışmalar yapmayı seviyorum.`,
      aboutMeParagraph2: `Öğrenmeye açığım ve isterse herkesin istediği alanda başarılı
      olabileceğine inanıyorum.`,

      projectsHeading: "Projeler",
      githubLinks: "GitHub sayfasına yönlendiriliyorsunuz!",
      viewSiteLink: "Siteye yönlendiriliyorsunuz!",
      viewSite: "Siteyi Görüntüle",

      lets1: "Bana mesaj gönderin!",
      lets2: "Bir sorunuz ya da öneriniz mi var, yoksa sadece merhaba mı demek istiyorsunuz? Bana ulaş.",
      cv: "Özgeçmiş",
      cvDownloadAlert:"Özgeçmişe yönlendiriliyorsunuz!",
      mailAlert: "Posta göndermek istiyor musunuz?",
      githubAlert: "GitHub sayfasına yönlendiriliyorsunuz!",
      linkedinAlert: "LinkedIn sayfasına yönlendiriliyorsunuz!",
      skill: "Yetenekler",
    },
  },
};

i18n.use(initReactI18next).use(LanguageDetector).init({
  fallbackLng: `en`,
  resources,
});

export default i18n;
