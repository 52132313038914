const projectData = { 
  data: [
  {
    "projectName": "Grateful Diary",
    "projectDescription":
      "A gratitude journal is a kind of diary where one often writes down the things they are grateful for in order to focus on the positive things in their life and to feel a sense of gratitude and thankfulness.",
    "projectProgrammes": ["react", "redux", "redux-thunk"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10-challenge",
    "viewSiteLink": "https://ayse-notebook.vercel.app/",
    "projectImg": "s10-challenge.png"
  },
  {
    "projectName": "Calculator",
    "projectDescription":
      "With this application, users can easily perform basic mathematical operations. This project is a good example of how modern web technologies can be integrated to develop a user-friendly tool.",
    "projectProgrammes": ["react", "redux", "reducer"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g1-reducer-calculator",
    "viewSiteLink": "https://calculator-ayse.vercel.app/",
    "projectImg": "s10g1.png"
  },
  {
    "projectName": "Movies",
    "projectDescription":
      "This project is designed as a platform that allows users to add movies, select their favorites and access movie information through a comprehensive movie database.",
    "projectProgrammes": ["react", "redux", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g2-redux-filmler",
    "viewSiteLink": "https://redux-films-ayse.vercel.app/movies",
    "projectImg": "s10g2.png"
  },
  {
    "projectName": "Movie Watch List",
    "projectDescription":
      "This project aims to allow users to add and delete movies from lists or categories in the existing movie database.",
    "projectProgrammes": ["react", "redux", "redux-logger"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g3-redux-watchlist",
    "viewSiteLink": "https://watchlist-ayse.vercel.app/",
    "projectImg": "s10g3.png"
  },
  {
    "projectName": "Get Random Jokes",
    "projectDescription":
      "Our system pulls a different joke from the database each time and presents it to the user. Thus, each user is greeted with a unique joke and has an unexpected experience.",
    "projectProgrammes": ["react", "localStorage", "toastify"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g4-thunk-and-apis",
    "viewSiteLink": "https://get-random-jokes.vercel.app/",
    "projectImg": "s10g4.png"
  },
  {
    "projectName": "Shopping Cart",
    "projectDescription":
      "This project aims to make the shopping process more efficient by providing shopping carts to users on the e-commerce platform.",
    "projectProgrammes": ["react", "redux", "context"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g1-shopping-cart",
    "viewSiteLink": "https://shopping-cart-ayse.vercel.app/",
    "projectImg": "s11g1.png"
  },
  {
    "projectName": "Friends",
    "projectDescription":
      "This project provides a platform for users to manage their friends and interact with the database. It is coded using React, react-hook-form, localStorage and axios technologies.",
    "projectProgrammes": ["react", "localStorage", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g2-client-auth-friends",
    "viewSiteLink": "https://friends-database.vercel.app/",
    "projectImg": "s11g2.png"
  },
  {
    "projectName": "Favorite Movies",
    "projectDescription":
      "This project allows users to manage their favorite movies and helps them interact with the database. It is developed using React and includes CRUD (Create, Read, Update, Delete) operations, localStorage and axios technologies.",
    "projectProgrammes": ["react", "CRUD", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g3-crud-filmler",
    "viewSiteLink": "https://crudmovies-project.vercel.app/movies",
    "projectImg": "s11g3.png"
  },

  {
    "projectName": "Handmade Pizza",
    "projectDescription":
      "Our project aims to enable users to create orders with accurate information and review order summaries.",
    "projectProgrammes": ["react", "yup", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s7-challenge-pizza",
    "viewSiteLink": "https://handmade-pizza.vercel.app",
    "projectImg": "s7-challenge.png"
  }
],

 dataTr: [
  {
    "projectName": "Minnet Günlüğü",
    "projectDescription":
      "Minnet günlüğü, kişinin hayatındaki olumlu şeylere odaklanması, minnettarlık ve şükran duygusunu hissetmesi için sık sık teşekkür ettiği şeyleri yazdığı bir tür günlüktür.",
    "projectProgrammes": ["react", "redux", "redux-thunk"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10-challenge",
    "viewSiteLink": "https://ayse-notebook.vercel.app/",
    "projectImg": "s10-challenge.png"
  },
  {
    "projectName": "Hesap Makinesi",
    "projectDescription":
      "Kullanıcılar bu uygulama sayesinde temel matematiksel işlemleri kolaylıkla gerçekleştirebilirler. Bu proje, modern web teknolojilerinin nasıl entegre edilerek kullanıcı dostu bir araç geliştirilebileceğinin güzel bir örneğidir.",
    "projectProgrammes": ["react", "redux", "reducer"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g1-reducer-calculator",
    "viewSiteLink": "https://calculator-ayse.vercel.app/",
    "projectImg": "s10g1.png"
  },
  {
    "projectName": "Filmler",
    "projectDescription":
      "Bu proje, kullanıcılara kapsamlı bir film veritabanı aracılığıyla filmler ekleyebilme, favorilerini seçebilme ve film bilgilerine ulaşabilme olanağı sunan bir platform olarak tasarlanmıştır.",
    "projectProgrammes": ["react", "redux", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g2-redux-filmler",
    "viewSiteLink": "https://redux-films-ayse.vercel.app/movies",
    "projectImg": "s10g2.png"
  },
  {
    "projectName": "Film İzleme Listesi",
    "projectDescription":
      "Bu proje, kullanıcılara mevcut film veritabanında filmleri eklemelerini ve listelerden ya da kategorilerden silmelerini sağlamayı hedeflemektedir.",
    "projectProgrammes": ["react", "redux", "redux-logger"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g3-redux-watchlist",
    "viewSiteLink": "https://watchlist-ayse.vercel.app/",
    "projectImg": "s10g3.png"
  },
  {
    "projectName": "Rastgele Şakalar Al",
    "projectDescription":
      "Sistemimiz, veritabanından her seferinde farklı bir şaka çekerek kullanıcıya sunar. Böylece her kullanıcı benzersiz bir şaka ile karşılanır ve beklenmedik bir deneyim yaşar.",
    "projectProgrammes": ["react", "localStorage", "toastify"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s10g4-thunk-and-apis",
    "viewSiteLink": "https://get-random-jokes.vercel.app/",
    "projectImg": "s10g4.png"
  },
  {
    "projectName": "Alışveriş Sepeti",
    "projectDescription":
      "Bu proje, e-ticaret platformundaki kullanıcılara alışveriş sepeti imkanı sağlayarak, alışveriş sürecini daha verimli kılmayı hedeflemektedir.",
    "projectProgrammes": ["react", "redux", "context"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g1-shopping-cart",
    "viewSiteLink": "https://shopping-cart-ayse.vercel.app/",
    "projectImg": "s11g1.png"
  },
  {
    "projectName": "Arkadaşlar",
    "projectDescription":
      "Bu proje, kullanıcılara arkadaşlarını yönetebilecekleri ve veritabanıyla etkileşimde bulunabilecekleri bir platform sunmaktadır. React, react-hook-form, localStorage ve axios teknolojileri kullanılarak kodlanmıştır.",
    "projectProgrammes": ["react", "localStorage", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g2-client-auth-friends",
    "viewSiteLink": "https://friends-database.vercel.app/",
    "projectImg": "s11g2.png"
  },
  {
    "projectName": "Favori Filmler",
    "projectDescription":
      "Bu proje, kullanıcıların en sevdikleri filmleri yönetebilmelerine olanak tanır ve veritabanıyla etkileşime geçmelerine yardımcı olur. React kullanılarak geliştirilmiş olup CRUD (Create, Read, Update, Delete) operasyonlarını, localStorage ve axios teknolojilerini içermektedir.",
    "projectProgrammes": ["react", "CRUD", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s11g3-crud-filmler",
    "viewSiteLink": "https://crudmovies-project.vercel.app/movies",
    "projectImg": "s11g3.png"
  },
  {
    "projectName": "El Yapımı Pizza",
    "projectDescription":
      "Projemiz, kullanıcıların doğru bilgilerle siparişlerini oluşturmasını ve sipariş özetlerini incelemesini amaçlamaktadır.",
    "projectProgrammes": ["react", "yup", "axios"],
    "gitHubLink": "https://github.com/ayseesahin/fsweb-s7-challenge-pizza",
    "viewSiteLink": "https://handmade-pizza.vercel.app",
    "projectImg": "s7-challenge.png"
  }] 
};
export default projectData;
